import Vue from 'vue'
import Component from 'vue-class-component'

import { validationMixin } from '@/apps/core/mixins/validations'
import { createNotification, createErrorNotification } from '@/lib/unnotificationsqueue'
import { mapGetters } from 'vuex'

@Component({
  mixins: [validationMixin],
  props: {},
  computed: {
    ...mapGetters('forecasting', ['getForecastZeroItems']),

    switchZeroItems: {
      get () {
        return this.getForecastZeroItems
      },
      set (newValue) {
        this.$store.dispatch('forecasting/setForecastZeroItems', newValue)
      }
    }
  }
})

class ForecastingByDistributorFilters extends Vue {
  forecastItems = []
  channelItems = [
    {
      id: 'forecastretail__gestor',
      name: 'Retail',
      customer_level_name: 'forecastretail__cadena'
    },
    {
      id: 'forecasthoreca__gestor',
      name: 'Horeca',
      customer_level_name: 'forecasthoreca__cadena'
    },
    {
      id: 'forecastportugal__gestor',
      name: 'Portugal',
      customer_level_name: 'forecastportugal__cadena'
    },
    {
      id: 'forecastooh__gestor',
      name: 'OOH',
      customer_level_name: 'forecastooh__cadena'
    },
    {
      id: 'forecastexport__gestor',
      name: 'Export',
      customer_level_name: 'forecastexport__cadena'
    }
  ]
  kamItems = []
  customerItems = []
  selectedForecast = null
  selectedChannel = null
  selectedKam = null
  selectedCustomer = null
  selectedCustomerLevel = null

  loading = false
  isLoadingForecast = false
  isLoadingChannels = false
  isLoadingCustomers = false
  isLoadingKams = false
  isLoadingValidationBtn = false
  noActiveForecastTable = true

  disabledForecastSelector = true
  disabledChannelSelector = true
  disabledCustomerSelector = true
  disabledKamSelector = true

  async mounted () {
    this.$store.dispatch('forecasting/setForecastZeroItems', true)
    await this.getForecasts()
  }

  beforeDestroy () {
    this.$store.dispatch('forecasting/setForecastZeroItems', false)
  }

  get isShowBtnDisabled () {
    return !(this.selectedForecast && this.selectedChannel && this.selectedKam && this.selectedCustomer && this.selectedCustomer !== '')
  }

  /**
   * Event handler for save button: emits filters to component
   */
  async getForecasts () {
    this.disabledForecastSelector = true
    this.disabledChannelSelector = true
    this.disabledCustomerSelector = true
    this.disabledKamSelector = true
    this.isLoadingForecast = true
    this.forecastItems = []
    let tmpList = await this.$store.dispatch('forecasting/getForecastList')
    tmpList.forEach(elem => {
      elem['customName'] = `${elem.name} - ${elem.status || 'N/A'}`
      this.forecastItems.push(elem)
    })
    this.setFirstForecast()
    this.disabledForecastSelector = false
    this.disabledChannelSelector = false
    this.isLoadingForecast = false
  }

  async getForecastKamList () {
    this.disabledKamSelector = true
    this.disabledCustomerSelector = true
    this.isLoadingKams = true
    this.selectedKam = null
    this.selectedCustomer = null
    this.kamItems = []
    this.customerItems = []
    try {
      let tmpList = await this.$store.dispatch(
        'forecasting/getForecastKams', { forecastId: this.selectedForecast.id, levelPk: this.selectedChannel.id })
      tmpList.forEach(elem => {
        elem['customName'] = `${elem.name}`
        this.kamItems.push(elem)
      })
      // eslint-disable-next-line no-undef
      this.kamItems = _.sortBy(this.kamItems, 'name')
      this.disabledKamSelector = false
    } catch (err) {
      this.$store.dispatch('addNotification', createErrorNotification(this.$t('forecasting.kamListError')))
    }
    this.isLoadingKams = false
  }

  async getForecastCustomerList () {
    this.disabledCustomerSelector = true
    this.isLoadingCustomers = true
    this.selectedCustomer = null
    this.customerItems = []
    try {
      let tmpList = await this.$store.dispatch('forecasting/getForecastCustomers', [ this.selectedCustomerLevel, this.selectedKam.id, this.selectedForecast.id ])
      tmpList.forEach(elem => {
        elem['customName'] = `${elem.name}`
        this.customerItems.push(elem)
      })
      if (tmpList.length === 0) {
        this.$store.dispatch('addNotification', createNotification(this.$t('forecasting.customerListWarning'), 'warning'))
      } else {
        this.disabledCustomerSelector = false
      }
    } catch (e) {
      this.$store.dispatch('addNotification', createErrorNotification(this.$t('forecasting.customerListError')))
    }
    this.isLoadingCustomers = false
  }

  setFirstForecast () {
    if (this.selectedForecast === null && this.forecastItems.length > 0) {
      this.selectedForecast = this.forecastItems[0]
    }
  }

  async selectedNewForecast () {
    this.initializeForecastVariables()
  }

  async selectedNewChannel () {
    await this.getForecastKamList()
    this.selectedCustomerLevel = this.selectedChannel['customer_level_name']
  }

  async selectedNewKam () {
    await this.getForecastCustomerList()
  }

  initializeForecastVariables () {
    this.$emit('hideTable', true)
    this.noActiveForecastTable = true
    this.customerItems = []
    this.selectedChannel = null
    this.selectedCustomer = null
    this.isLoadingCustomers = false
    this.disabledCustomerSelector = true
  }

  onShowForecast () {
    let forecastData = {
      forecast: this.selectedForecast,
      channel: this.selectedChannel,
      distributor: this.selectedCustomer
    }
    this.$store.dispatch('forecasting/setForecastDataValues', forecastData)
    this.noActiveForecastTable = false
  }

  async validationForecastBtn () {
    let validParam
    try {
      this.isLoadingValidationBtn = true
      if (this.selectedForecast.is_validated) {
        validParam = true
        const response = await this.$store.dispatch('forecasting/invalidateForecast', this.selectedForecast.id)
        this.$store.dispatch('addNotification', createNotification(response.data.message, response.data.level))
        this.selectedForecast.is_validated = false
      } else {
        validParam = false
        const response = await this.$store.dispatch('forecasting/validateForecast', this.selectedForecast.id)
        this.$store.dispatch('addNotification', createNotification(response.data.message, response.data.level))
        this.selectedForecast.is_validated = true
      }
    } catch (err) {
      if (validParam) {
        this.$store.dispatch('addNotification', createErrorNotification(this.$t('forecasting.invalidationError')))
      } else {
        this.$store.dispatch('addNotification', createErrorNotification(this.$t('forecasting.validationError')))
      }
    } finally {
      this.isLoadingValidationBtn = false
    }
  }
}

export default ForecastingByDistributorFilters
